import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";

import ReferralLogEditModal from "./ReferralLogEditModal";

import Pagination from "react-reactstrap-pagination";

import moment from "moment";

import api from "~/services/api";

import Loader from "~/components/Loader/Loader";
import { applyColorToStatus, translateStatus } from "./utils";

export default class ReferralLog extends Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);

    this.state = {
      pageData: [],
      loading: true,
      selectedPage: 1,
      totalItems: 0,
      perPage: 30,
      lastPage: 1,
      searchQuery: "",
      statusFilter: "",
    };

    this.loadData();
  }

  loadData = async (selectedPage = this.state.selectedPage) => {
    const { statusFilter } = this.state;

    let queryParams = `?page=${selectedPage}`;
    if (statusFilter) queryParams += `&status=${statusFilter}`;

    const { data } = await api.get(`referrals/requests${queryParams}`);

    this.setState({
      ...this.state,
      pageData: data.data,
      loading: false,
      totalItems: data.total,
      lastPage: data.lastPage,
      selectedPage: selectedPage,
      referralLogEditModal: false,
      selectedReferralLog: null,
    });
  };

  saveData = async (referralLog) => {
    await api.put(`referrals/requests/${referralLog.id}`, referralLog);
    this.loadData();
  }

  handleStatusFilterChange = (event) => {
    const newStatusFilter = event.target.value;
    this.setState({ statusFilter: newStatusFilter, selectedPage: 1 }, () => {
      this.loadData();
    });
  };

  handleEditReferralLog = (referralLog) => {
    this.setState({
      ...this.state,
      selectedReferralLog: referralLog,
      referralLogEditModal: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}

        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col lg={7}>
                  <h4 className="page-title">Recompensas Resgatadas</h4>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            <div className="form-group">
              <label htmlFor="statusFilter">Filtrar por status:</label>
              <select
                className="form-control"
                id="statusFilter"
                value={this.state.statusFilter}
                onChange={this.handleStatusFilterChange}
              >
                <option value="">Todos</option>
                <option value="FINISHED">Finalizados</option>
                <option value="PENDING">Pendentes</option>
                <option value="CANCELED">Cancelados</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card style={{ overflow: "auto" }}>
              <CardBody>
                <table
                  className="table table-centered table-hover mb-0"
                  id="btn-editable"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Usuário</th>
                      <th>Recompensas</th>
                      <th>Valor Total</th>
                      <th>Status</th>
                      <th>Data de solicitação</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.pageData.map((data, dIdx) => (
                      <tr id="1" key={`id_${dIdx}`}>
                        <td>
                          <span className="tabledit-span tabledit-identifier">
                            {data.id}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {data.user.name} - {data.user.email}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {(data.items.length > 1 && "Mais de um item...") ||
                              data.items[0].referral_reward_name}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {data.items.reduce((accumulator, currentItem) => {
                              return accumulator + currentItem.used_points * currentItem.quantity;
                            }, 0)}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className={applyColorToStatus(data.status)}>
                            {translateStatus(data.status)}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {data.created_at
                              ? moment(data.created_at).format("DD/MM/YYYY")
                              : "-"}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <Button
                            color="primary"
                            onClick={() => this.handleEditReferralLog(data)}
                          >
                            Gerenciar
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ overflow: "auto" }}>
            <Pagination
              firstPageText="Início"
              lastPageText="Fim"
              previousPageText="Anterior"
              nextPageText="Próximo"
              totalItems={this.state.totalItems}
              pageSize={this.state.perPage}
              maxPaginationNumbers={5}
              defaultActivePage={1}
              onSelect={this.loadData}
            />
          </Col>
        </Row>

        {this.state.referralLogEditModal && (
          <ReferralLogEditModal
            open={this.state.referralLogEditModal}
            toggle={() =>
              this.setState({ ...this.state, referralLogEditModal: false })
            }
            order={this.state.selectedReferralLog}
            onSave={this.saveData}
          />
        )}
      </React.Fragment>
    );
  }
}
