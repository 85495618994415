import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, Modal, ModalBody, ModalHeader, ModalFooter, Input, FormGroup, Form, Label} from 'reactstrap';
import moment from 'moment';
import Pagination from "react-reactstrap-pagination";

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import AthleteDetailsModal from '../../TrainingPlan/AthleteDetailModal';

import UserNewModal from '../create';
import UserShowModal from '../show';
import SignatureModal from '../signature';

// import AthleteDetailsModal from './AthleteDetailModal';

import api from '~/services/api';

import Loader from '~/components/Loader/Loader';

import { Container } from './styles';

export default class Communications extends Component {
    constructor(props) {
        super(props);

        this.loadUsers = this.loadUsers.bind(this);

        this.state = {
            users: [],
            loading: true,
            showNewUserModal: false,
            showSignatureModal: false,
            showUserActivitiesModal: false,
            userToEdit: null,
            userToShow: null,
            searchQuery: '',
            selectedPage: 1,
            totalItems: 0,
            perPage: 20,
            lastPage: 1
        }

        this.loadUsers();
    }

    loadUsers = async (selectedPage) => {
        const { data } = await api.get(`users?page=${selectedPage}&limit=${this.state.perPage}`);

        this.setState({ ...this.state, users: data.data, loading: false, totalItems: data.total, perPage: data.perPage, lastPage: data.lastPage, selectedPage: selectedPage });
    }

    handleNewUserModal = async (userToEdit) => {

        userToEdit = (userToEdit && userToEdit.id > 0) ? userToEdit : null;

        this.setState({ userToEdit, showNewUserModal: !this.state.showNewUserModal });
    }

    handleSignatureModal = async (user) => {
        if (user && user.subscriptions) {
            // const { data } = await api.get(`signatures/${user.signature.id}`);
            this.setState({ signatureToShow: user.subscriptions[0], userToShow: user, showSignatureModal: !this.state.showSignatureModal });
        } else if (!user) {
            this.setState({ signatureToShow: null, userToShow: null, showSignatureModal: !this.state.showSignatureModal });
        }
    }
    
    handleUpdateSubscriptionModal = async (user) => {
        if (user && user.subscriptions) {

            var result = window.confirm("Tem certeza que deseja atuaizar essa assinatura la na Vindi?");

            if (result) {
                const resp = await api.put(`subscriptions/${user.subscriptions[0].id}`, {})

                if (resp && resp.status === 200) {
                    toast.success('Assinatura atualiada com sucesso na Vindi')
                } else {
                    toast.error('Erro ao atualizar assinatura na Vindi')
                }
            }
        }
    }

    

    handleUserDetailModal = async (userToShow) => {
        this.setState({ userToShow, showUserActivitiesModal: !this.state.showUserActivitiesModal });
    }
    
    handleSimulateUserLogin = async (userToSimulate) => {
        // const data = {
        //     email: userToSimulate.email,
        //     email: userToSimulate.email,
        //     email: userToSimulate.email,
        // }

        // const resp = await api.put(`sessions_simulate`, {})

        // if (resp && resp.status === 200) {
        //     toast.success('Assinatura atualiada com sucesso na Vindi')
        // } else {
        //     toast.error('Erro ao atualizar assinatura na Vindi')
        // }
    }

    handleSearchUser = async () => {
        const { data } = await api.get(`users?searchQuery=${this.state.searchQuery}`);

        this.setState({ ...this.state, users: data, loading: false });
    }

    handleEmailRecoveryPass = async (user) => {
        const { data } = await api.post(`/passwords`, {
            email: user.email, redirect_url: `${process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_URL}`
        });
        
        if (data.status === 200) {
            toast.success('Email de recuperação de senha enviado com sucesso')
        } else {
            toast.error('Erro ao enviar email de recuperação de senha')
        }
    }

    closeBtn = () => {
        return <button className="close" onClick={this.handleNewUserModal}>&times;</button>;
    }

    _handleSendPush = async () => {
        const data = {
            title: this.state.title,
            content: this.state.content,
            push_content: this.state.push_content,
            send_push: true,
            users: [ 1, 2, 4, 5, 10, 2966 ]
        }

        const pushResp = await api.post('user_communications', data)

        if (pushResp?.status === 200) {
            toast.success('Mensagem enviada com sucesso')
        } else {
            toast.error('Erro ao enviar mensagem')
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loader />}

                <Row>
                    <Col>
                        <div className="page-title-box">
                            <Row>
                                <Col lg={7}>
                                    <h4 className="page-title">Mensagens de usuários</h4>
                                </Col>
                                <Col lg={5} className="mt-lg-3 mt-md-0">

                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Pushs</p>
                                <Button color="info" style={{ marginTop: 15, marginBottom: 10 }} onClick={this.handleNewUserModal}>Nova mensagem para ADMS</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 7, offset: 3 }}>
                        {/* <Pagination
                            firstPageText="Início"
                            lastPageText="Fim"
                            previousPageText="Anterior"
                            nextPageText="Próximo"
                            totalItems={this.state.totalItems}
                            pageSize={this.state.perPage}
                            maxPaginationNumbers={5}
                            defaultActivePage={1}
                            onSelect={this.loadUsers}
                        /> */}
                    </Col>
                </Row>

                <Modal isOpen={this.state.showNewUserModal} toggle={this.handleNewUserModal}>
                    <ModalHeader toggle={this.handleNewUserModal} close={this.closeBtn}>Enviar notificação</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label style={{marginTop: 20}} for="cancellationReason">Titulo da mensagem</Label> <br />
                                <i>Titulo do push e também da mensagem</i>
                                <Input
                                    onChange={
                                        e => this.setState({ ...this.state, title: e.target.value})
                                    }
                                    type="text" name="cancellationReason" id="cancellationReason" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="push_content">Conteúdo do push</Label><br />
                                <i>Esse conteúdo só sera visível no corpo do push. (máximo de 255 caracteres)</i>
                                <Input
                                    onChange={
                                        e => this.setState({ ...this.state, push_content: e.target.value})
                                    }
                                    type="textarea" name="push_content" id="push_content" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="content">Conteúdo da Mensagem</Label><br />
                                <i>Esse conteúdo só sera visível no corpo da mensagem.</i>
                                <Input
                                    onChange={
                                        e => this.setState({ ...this.state, content: e.target.value})
                                    }
                                    type="textarea" name="content" id="content" />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleNewUserModal}>Sair</Button>&nbsp;
                        <Button color="primary" onClick={this._handleSendPush}>Enviar Mensagem</Button>
                    </ModalFooter>
                </Modal>

                {/* {this.state.showNewUserModal && <UserNewModal open={this.state.showNewUserModal} user={this.state.userToEdit} toggle={this.handleNewUserModal} />}
                {this.state.showUserActivitiesModal && <UserShowModal open={this.state.showUserActivitiesModal} user={this.state.userToShow} toggle={this.handleUserDetailModal} />}
                {this.state.showSignatureModal && <SignatureModal open={this.state.showSignatureModal} signature={this.state.signatureToShow} user={this.state.userToShow} toggle={this.handleSignatureModal} />}
                {this.state.userDetailsModal && <AthleteDetailsModal open={this.state.userDetailsModal} justShow={true} toggle={() => this.setState({ ...this.state, userDetailsModal: false })} athlete={this.state.userToShow} />} */}
            </React.Fragment>

        );

    };
}
