import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Pagination from "react-reactstrap-pagination";
import { toast } from 'react-toastify';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';
import ModalEdit from './ModalEdit'
import ModalCreate from './ModalCreate'
import './styles.css';
import { formatBigText } from '~/services/util'
import { debounce } from 'lodash';

const statuses = [
  { name: 'Todos', id: 'all' },
  { name: 'Ativos', id: 'active' },
  { name: 'Inativos', id: 'inactive' }
]

export default function Coupons() {
  const [coupons, setCoupons] = useState([])
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [lastPage, setLastPage] = useState(1)
  const [showDDStatus, setShowDDStatus] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState({ name: 'Todos', id: 'all' })
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [couponToShow, setCouponToShow] = useState(null)

  const nameDelayedQuery = useRef(
    debounce(e => {
      setName(e)
    }, 1000)
  ).current

  const loadCoupons = useCallback(async (selectedPage) => {
    setLoading(true)

    let URL = `coupons?page=${selectedPage}&status=${selectedStatus.id}`
    if (name && name !== '') {
      URL += `&code=${name}`
    }

    try {
      const response = await api.get(URL)

      setCoupons(response.data?.data)
      setTotalItems(response.data?.total)
      setPerPage(response.data?.perPage)
      setLastPage(response.data?.lastPage)
      setSelectedPage(response.data?.page)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error('Erro ao carregar cupoms!')
    }
  }, [selectedStatus.id, name])

  useEffect(() => {
    loadCoupons(1);
  }, [loadCoupons])

  const formatType = (type) => {
    switch (type) {
      case 'MONETARY':
        return 'Monetário'
      case 'PERCENTAGE':
        return 'Porcento'
      default:
        return '-'
    }
  }

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return 'Ativo'
      case 0:
        return 'Inativo'
      default:
        return '-'
    }
  }

  return (
    <>
      {loading && <Loader />}

      {couponToShow && <ModalEdit coupon={couponToShow} showModal={modalEdit} toggle={() => setModalEdit(prev => !prev)} loadCoupons={loadCoupons} />}

      <ModalCreate showModal={modalCreate} toggle={() => setModalCreate(prev => !prev)} loadCoupons={loadCoupons} />

      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={7}>
                <h4 className="page-title">Cupons</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card style={{ overflow: 'auto' }}>
            <CardBody>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="card-body-header-title">
                  <p>Cupons ({totalItems})</p>
                  <p className="ml-2 btn btn-primary" onClick={() => setModalCreate(true)}>Cadastrar novo cupom</p>
                </div>
                <div className="card-body-header-status mb-3">
                  <div className="mr-2" style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Cupom</span>
                    <input style={{ padding: 2 }} type="text" name="searchName" id="searchName" onChange={e => nameDelayedQuery(e.target.value)} placeholder="Código" />
                  </div>
                  <div className="card-body-header-status-dropdown">
                    <span>Filtrar por status</span>
                    <Dropdown size="sm" isOpen={showDDStatus} toggle={() => setShowDDStatus(prev => !prev)} >
                      <DropdownToggle className="card-body-header-status-dropdown-toggle">{selectedStatus?.name}</DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true, order: 890, fn: (data) => {
                              return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                            }
                          }
                        }} >
                        {statuses.map((elem, index) => {
                          return (
                            <DropdownItem
                              key={`st_${index}`}
                              onClick={() => {
                                setSelectedPage(1)
                                setSelectedStatus(elem)
                              }}>{elem.name}</DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <table className="table table-centered table-hover mb-0" id="btn-editable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th>Planos</th>
                    <th>Tipo</th>
                    <th>Valor</th>
                    <th>Limite de uso</th>
                    <th>Limite de pagamentos</th>
                    <th>Emails</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {coupons &&
                    coupons.length > 0 &&
                    coupons.map((coupon, cIdx) => (
                      <tr key={`cou_${cIdx}`} className="table-tbody-tr" onClick={() => {
                        setCouponToShow(coupon)
                        setModalEdit(true)
                      }}>
                        <td>
                          <span className="tabledit-span tabledit-identifier">{coupon.id}</span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {coupon.code}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {coupon.description ? formatBigText(coupon.description) : 'n/a'}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {coupon.vindi_plan_ids ? formatBigText(coupon.vindi_plan_ids) : 'n/a'}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {formatType(coupon.type)}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {coupon.value}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {coupon.usage_limit || coupon.usage_limit === 0 ? coupon.usage_limit : 'Ilimitado'}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {coupon.quantity_payments_applicable ? coupon.quantity_payments_applicable : 'Ilimitado'}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className="tabledit-span">
                            {coupon.restricted_emails ? formatBigText(coupon.restricted_emails) : 'n/a'}
                          </span>
                        </td>
                        <td className="tabledit-view-mode">
                          <span className={`tabledit-span badge ${coupon.active === 1 ? 'badge-primary' : 'badge-danger'}`}>
                            {formatStatus(coupon.active)}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ overflow: 'auto' }}>
          <Pagination
            firstPageText="Início"
            lastPageText="Fim"
            previousPageText="Anterior"
            nextPageText="Próximo"
            totalItems={totalItems}
            pageSize={perPage}
            maxPaginationNumbers={lastPage}
            defaultActivePage={selectedPage}
            onSelect={loadCoupons}
          />
        </Col>
      </Row>
    </>

  );
}
