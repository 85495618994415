import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Table, Button } from 'reactstrap';
import moment from 'moment'
import { api2 } from '~/services/api'
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { Container } from './styles'
import NewHomeBannerModal from './NewHomeBannerModal';

function HomeBanner() {
    const [loading, setLoading] = useState(false)
    const [banners, setBanners] = useState([])
    const [bannerModal, setBannerModal] = useState(false)
    const [editId, setEditId] = useState(null)

    const loadBanners = useCallback(async () => {
        try {
            setLoading(true)

            const { data } = await api2.get(`home-banner`)

            setBanners(data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Erro ao carregar os banners')
        }
    }, [])

    const removeBanner = async (id) => {
        let result = window.confirm("Tem certeza que deseja remover permanentemente esse banner?");
        if(!result) return;
        api2.delete(`home-banner/${id}`)
        .then(() => {
            loadBanners();
            toast.success('Banner removido com sucesso!');
        })
        .catch((err) => {
            console.log(err);
            toast.error('Erro ao remover o Banner')
        })
    }

    const openEditModal = (id) => {
        setEditId(id)
        setBannerModal(true)
    }

    const closeModal = () => {
        setEditId(null)
        setBannerModal(false)
    }

    useEffect(() => {
        loadBanners()
    }, [loadBanners])

    return (
        <Container>
            {bannerModal && <NewHomeBannerModal loadBanners={loadBanners} open={bannerModal} toggle={closeModal} editId={editId}/>}
            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Benefícios</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col>
                    <Card>
                        <CardBody>
                            {loading && <Loader />}
                            <Button style={{ marginBottom: 10 }} color="success" onClick={() => setBannerModal(true)}>Cadastrar novo banner</Button>
                            <Row>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nome</th>
                                            <th>Descrição</th>
                                            <th>Link</th>
                                            <th>Validade</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {banners.length > 0 && banners.map((banner, index) => (
                                            <tr key={banner._id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{banner.title}</td>
                                                <td>{banner.description ?? ''}</td>
                                                <td>{banner.banner_confirm_button_type === 'URL' ? <a href={banner.banner_confirm_button_action}><b>Link</b></a> : ''}</td>
                                                <td>{banner.expiration_date ? moment(banner.expiration_date).format('DD/MM/YYYY') : ''}</td>
                                                <td className="tabledit-view-mode">
                                                    <Button color="primary" onClick={() => openEditModal(banner._id)}>Editar</Button>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <Button color="danger" onClick={() => removeBanner(banner._id)}>Excluir</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default HomeBanner;