import React, { useState, useEffect, useCallback } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Button,
  Row, Col, InputGroup, InputGroupAddon, Input, Progress, Modal, ModalBody, Spinner
} from 'reactstrap';
import { toast } from 'react-toastify';
import { MdAdd } from 'react-icons/md';
import moment from 'moment';
import TrainingPlanNewModal from './NewTrainingPlanModal';
import AthleteDetailsModal from './AthleteDetailModal';
import TrainingPlanDetailModal from './TrainingPlanDetailModal';
import TrainingNewModal from '../Training/new/TrainingNewModal';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { DragDropContext } from 'react-beautiful-dnd';
import api from '~/services/api';
import { useSelector } from 'react-redux';
import TrainingItem from './components/trainingItem';
import TrainingCalendarItem from './components/trainingCalendarItem';
import TrainingShowModal from '~/pages/Training/show/TrainingShowModal';
import buildData, { formatTrainingJSON } from './buildData';
import queryString from 'query-string';
import './style.css';

function formatTime(time) {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

export default function TrainingPlanBeta(props) {
  const profile = useSelector(state => state.user.profile);

  const [athletes, setAthletes] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [userActivities, setUserActivities] = useState([]);
  const [trainingPlans, setTrainingPlans] = useState([]);
  const [trainingPlan, setTrainingPlan] = useState({ id: -1 });
  const [loadedItem, setLoadedItem] = useState({ itemID: 'N/A', title: 'N/A Carregado', itemName: 'Nenhum', itemInternalName: 'nenhum', itemType: '', data: {} });
  const [agenda, setAgenda] = useState([]);
  const [activeTab, setActiveTab] = useState('2');

  const [dndState, setDndState] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);

  const [trainingPlanModal, setTrainingPlanModal] = useState(false);

  const [trainingNewModal, setTrainingNewModal] = useState(false);
  const [isTrainingEdit, setIsTrainingEdit] = useState(false);

  const [trainingToSave, setTrainingToSave] = useState({ trainingGroups: [] });
  const [trainingToEdit, setTrainingToEdit] = useState({ trainingGroups: [] });

  const [athleteDetailsModal, setAthleteDetailsModal] = useState(false);
  const [currentAthleteDetails, setCurrentAthleteDetails] = useState();
  const [athleteCurrentTrainingPlan, setAthleteCurrentTrainingPlan] = useState(null);
  const [currentCalendarDate, setCurrentCalendarDate] = useState();


  const [trainingPlanDetailsModal, setTrainingPlanDetailsModal] = useState(false);
  const [currentTrainingPlanDetails, setCurrentTrainingPlanDetails] = useState();

  const [summary, setSummary] = useState([]);

  const [searchQueryAthlete, setSearchQueryAthlete] = useState();
  const [searchQueryTrainingPlan, setSearchQueryTrainingPlan] = useState();
  const [searchQueryTraining, setSearchQueryTraining] = useState();

  const [trainingActivityShowModal, setTrainingActivityShowModal] = useState(false);
  const [trainingActivityToShow, setTrainingActivityToShow] = useState();
  const [trainingActivityColumnToShow, setTrainingActivityColumnToShow] = useState();
  const [trainingToRemove, setTrainingToRemove] = useState();
  const [planIdToLoadBackward, setPlanIdToLoadBackward] = useState(null);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location])

  async function loadData() {
    Promise.all([api.get('users?limit=10'), api.get('trainings?limit=10'), api.get('training_plans?limit=10')])
      .then(async ([usersResp, trainingsResp, trainingPlansResp]) => {
        setAthletes(usersResp.data);
        setTrainings(trainingsResp.data);
        setTrainingPlans(trainingPlansResp.data);

        const { dndData } = await buildData(trainingsResp.data, 24, null, null, 'DEFAULT');

        const columnOrdersList = Array.from(dndData.columnOrder);
        const lastIndex = (columnOrdersList.length - 1)
        columnOrdersList.splice(lastIndex, 1)

        setDndState(dndData);

        const currAgenda = [];
        let currentWeek = [];
        columnOrdersList.forEach((column, cidx) => {
          currentWeek.push(column)
          if ((cidx + 1) % 7 === 0) {
            currAgenda.push({ id: `row_${cidx + 1}`, days: currentWeek })
            currentWeek = [];
          }
        })

        setAgenda(currAgenda);
        verifyAthleteRedirect()
      });
  }

  const verifyAthleteRedirect = () => {
    const urlParsed = queryString.parse(props.location?.search);
    if (urlParsed?.aID && urlParsed?.aID > 0) {

      Promise.all([api.get(`/users/${urlParsed?.aID}`)])
        .then(async ([userResp]) => {
          if (userResp.status === 200) {
            const { data } = userResp;

            setSearchQueryAthlete(data.email)
            searchAthleteAction(data.email)
            setLoadedItem({ title: 'Atleta Carregado', img: data.s_photo, itemName: data.name, itemID: data.id, itemInternalName: data.email, itemType: 'athlete', data: data })
            setActiveTab('1')

            if (planIdToLoadBackward) {
              loadAthleteTrainingPlan({ id: urlParsed?.aID }, null, planIdToLoadBackward)
            } else {
              loadAthleteTrainingPlan({ id: urlParsed?.aID })
            }
          } else {
            setDataLoaded(true);
            toast.error('Erro ao carregar o atleta');
          }
        }).catch(error => {
          setDataLoaded(true);
          toast.error('Erro ao carregar o atleta');
        })
    } else {
      setDataLoaded(true);
    }
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  async function searchAthleteAction(searchQ = null) {
    const { data } = await api.get(`users?searchQuery=${searchQ || searchQueryAthlete}`)

    setAthletes(data);
  }

  async function searchTrainingPlanAction() {
    const { data } = await api.get(`training_plans?searchQuery=${searchQueryTrainingPlan}`)

    setTrainingPlans(data);
  }

  async function searchTrainingAction() {
    const { data } = await api.get(`trainings?searchQuery=${searchQueryTraining}`)

    setTrainings(data);

    const formatedDataIds = data.map((searchTrainingData) => {
      return `t-${searchTrainingData.id}`
    })

    const [trainingsToSelectJSON] = await formatTrainingJSON(data, true)

    setDndState({
      ...dndState,
      columns: {
        ...dndState.columns,
        tcolumns: {
          id: "tcolumns",
          trainingIds: formatedDataIds
        }
      },
      trainings: { ...dndState.trainings, ...JSON.parse(trainingsToSelectJSON.toString()) }
    });
  }

  async function openTrainingCalendar(trainingToOpen) {
    await setTrainingToSave(trainingToOpen)
    setTrainingNewModal(true)
  }

  async function openTrainingEdit(trainingToEdit) {
    await setTrainingToEdit(trainingToEdit)
    await setIsTrainingEdit(true);
    await setTrainingNewModal(true)
  }

  async function handleOpenActivityDetail(trainingToShow, context = null, columnID = null, trainingToRemove = null) {
    if (trainingToShow && trainingToShow?.obj_type === 'USR_ACT') {
      trainingToShow.trainingGroups = [];

      await setTrainingActivityToShow(trainingToShow)
      await setTrainingActivityShowModal(true);
    } else if (trainingToShow) {
      await setTrainingActivityToShow({ id: trainingToShow.tId, trainingGroups: [] })
      await setTrainingActivityShowModal(true);
      if (context === 'calendar' && columnID) {
        setTrainingActivityColumnToShow(columnID)
        setTrainingToRemove(trainingToRemove)
      }
    }
  }

  async function deleteTraining(trainingToRemove) {
    if (loadedItem.itemID === -1) {

    } else {
      let confirmStr = '';
      if (trainingToRemove.currTraining && trainingToRemove.currTraining.clone_type === 'TO_USER' && trainingToRemove.toDelete) {
        confirmStr = 'Tem certeza que deseja remover permanentemente esse treino do plano de treino do usuário?';
      } else if (trainingToRemove.currTraining && trainingToRemove.currTraining.clone_type === 'SCHEMA' && trainingToRemove.toDelete) {
        confirmStr = 'Tem certeza que deseja remover permanentemente esse treino do plano de treino?';
      }

      var result = window.confirm(confirmStr);

      if (result) {
        const resp = await api.delete(`trainings/${trainingToRemove.currTraining.id}`)

        if (resp.status === 204) {
          toast.success('Treino apagado com sucesso');
          return { ok: 'success' }
        } else {
          toast.error('Aconteceu um erro ao apagar o treino.');
          throw new Error('server error when training delete');
        }
      } else {
        throw new Error('Cancel training delete');
      }
    }
  }

  async function removeCalendarItem(trainingToRemove, columnID) {
    try {
      await deleteTraining(trainingToRemove)
    } catch (error) {
      return;
    }

    const columnTrainings = dndState.columns['tcolumns'];
    const columnCalendar = dndState.columns[columnID];


    const columnsCalendarIds = Array.from(columnCalendar.trainingIds);
    const calendarIdxToRemove = columnsCalendarIds.indexOf(trainingToRemove.id)
    columnsCalendarIds.splice(calendarIdxToRemove, 1);

    const columnsTrainingsIds = Array.from(columnTrainings.trainingIds);
    const trainingIdxToRemove = columnsTrainingsIds.indexOf(trainingToRemove.id)
    columnsTrainingsIds.splice(trainingIdxToRemove, 1);

    await delete dndState.trainings[trainingToRemove.id]

    const newCalendarColumn = {
      ...columnCalendar,
      trainingIds: columnsCalendarIds,
    };

    const newTrainingColumn = {
      ...columnTrainings,
      trainingIds: columnsTrainingsIds,
    };

    const newState = {
      ...dndState,
      columns: {
        ...dndState.columns,
        [newCalendarColumn.id]: newCalendarColumn,
        [newTrainingColumn.id]: newTrainingColumn,
      },
    }


    await setDndState(newState);
  }

  const onDragEnd = async result => {
    if (loadedItem.itemID === 'N/A') {
      toast.warning('Para criar um Plano de Treino, inicie pela tab de Plano de Treino');
      return;
    }

    const { destination, source, draggableId } = result;

    if (!destination) { return; }
    if (destination.droppableId === source.droppableId && destination.index === source.index) { return; }

    const start = dndState.columns[source.droppableId];
    const finish = dndState.columns[destination.droppableId];

    if (start.id !== 'tcolumns') {
      return;
    }

    if (start === finish) {
      const newTaskIds = Array.from(start.trainingIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        trainingIds: newTaskIds,
      };

      const newState = {
        ...dndState,
        columns: {
          ...dndState.columns,
          [newColumn.id]: newColumn,
        },
      }

      setDndState(newState);
      return;
    }

    // moving from one list to another
    const startTaskIds = Array.from(start.trainingIds);
    const finishTaskIds = Array.from(finish.trainingIds);

    const randomID = Math.floor(Math.random() * 10000);

    const newTrainingID = ('t-r' + randomID)
    const currentTraining = {};

    if (start.id !== 'tcolumns') {
      startTaskIds.splice(source.index, 1);
    } else {
      let clonedTraining = null;

      if (loadedItem.itemID > 0) {
        clonedTraining = await saveNewTrainingOnPT(finish, dndState.trainings[draggableId])
      }

      const newTrainingID = loadedItem.itemID > 0 && clonedTraining ? clonedTraining.id : dndState.trainings[draggableId].tId

      const lastDestinationIndex = (startTaskIds.length - 1)
      startTaskIds.splice(lastDestinationIndex, 0, newTrainingID);

      currentTraining.id = newTrainingID;
      currentTraining.show = false;
      // currentTraining.tId = dndState.trainings[draggableId].tId;
      currentTraining.tId = newTrainingID;
      currentTraining.tName = dndState.trainings[draggableId].tName;
      currentTraining.tIName = dndState.trainings[draggableId].tIName;
      currentTraining.currTraining = { ...dndState.trainings[draggableId].currTraining, id: newTrainingID };

      currentTraining.content = dndState.trainings[draggableId].content
    }

    finishTaskIds.splice(destination.index, 0, newTrainingID);

    const newStart = {
      ...start,
      trainingIds: startTaskIds,
    }

    const newFinish = {
      ...finish,
      trainingIds: finishTaskIds,
    };

    let newState = {};
    if (start.id !== 'tcolumns') {
      newState = {
        ...dndState,
        columns: {
          ...dndState.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };
    } else {
      newState = {
        ...dndState,
        trainings: {
          ...dndState.trainings,
          [newTrainingID]: currentTraining
        },
        columns: {
          ...dndState.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };
    }

    setDndState(newState)
    calcSummary(newState)
  }

  const saveNewTrainingOnPT = async (destFinish, training) => {

    if (loadedItem && training && training.tId && loadedItem.itemID > 0 && loadedItem.itemType && loadedItem.data) {

      const cloneType = loadedItem.itemType === 'athlete' ? 'TO_USER' : 'SCHEMA' // trainingPlan || athlete
      const trainingPlanID = loadedItem.itemType === 'athlete' ? athleteCurrentTrainingPlan.id : loadedItem.itemID
      const addTrainingToPT = {
        dateDay: destFinish.date,
        trainingId: training.tId,
        cloneType,
        trainingPlanID
      }

      try {
        const resp = await api.post(`training_plans/${trainingPlanID}/add_training`, addTrainingToPT)

        if (resp.status === 200) {
          if (loadedItem.itemType === 'athlete') {
            toast.success('Treino adicionado com sucesso ao plano de treino do usuário: ' + loadedItem.data.name);
          } else if (loadedItem.itemType === 'trainingPlan') {
            toast.success('Treino adicionado com sucesso ao plano de treino: ' + loadedItem.data.name);
          }

          return { id: resp.data.trainingID }
        } else {
          toast.error('Erro ao adicionar treino')
        }
      } catch (error) {
        toast.error('Erro ao adicionar treino')
      }

      return null;
    }
  }

  const handleShowTrainingDetail = async (training) => {
    await setTrainingActivityToShow({ id: training.tId, trainingGroups: [] })
    await setTrainingActivityShowModal(true);
  }

  const buildTrainings = useCallback(async () => {
    try {
      const column = dndState.columns['tcolumns'];

      const trainings = []

      column.trainingIds.forEach((trainingID, index) => {
        const training = dndState.trainings[trainingID];
        if (training && training.show)
          trainings.push(training);
      })

      return (
        <TrainingItem key={column.id} column={column} trainings={trainings} showTrainingDetailAction={handleShowTrainingDetail} />
      );
    } catch (error) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    buildTrainings()
  }, [buildTrainings])

  const buildTrainingList = () => { // UTILIZAR UM useMEMO aqui pra performance
    const column = dndState.columns['tcolumns'];

    const trainings = []

    column.trainingIds.forEach((trainingID, index) => {
      const training = dndState.trainings[trainingID];
      if (training && training.show)
        trainings.push(training);
    })

    return (
      <TrainingItem key={column.id} column={column} trainings={trainings} showTrainingDetailAction={handleShowTrainingDetail} />
    );
  }

  const calcSummary = async (currDndState) => {
    let trainingPlanWeek = {};
    const trainingPlanWeeks = [];

    let weekOrder = 1;

    let totalDistance = 0;
    let totalDuration = 0;
    let totalAltimetry = 0;
    let totalCalorie = 0;
    let totalCPS = 0;

    Object.keys(currDndState.columns).forEach((col, cidx) => {
      let currColumn = currDndState.columns[col];

      if (col !== 'tcolumns') {

        if (currColumn.trainingIds.length > 0) {
          currColumn.trainingIds.forEach((treId, tidx) => {
            const { currTraining } = currDndState.trainings[treId];

            if (currTraining.activity_type === 'DURATION' && currTraining.type === 'Run') {
              totalDuration += currTraining.duration || 0;
            } else if (currTraining.activity_type === 'DISTANCE') {
              totalDistance += currTraining.distance || 0;
            }

            if (currTraining.type === 'Run') {
              totalCPS += currTraining.cps || 0
              totalAltimetry += currTraining.altimetry || 0;
              totalCalorie += currTraining.calorie || 0;
            } else {
              totalDuration += currTraining.estimated_init || currTraining.estimated_end || 0;
            }
          })
        }
      }

      if ((cidx + 1) % 7 === 0) {
        trainingPlanWeek.week_order = weekOrder;

        trainingPlanWeek.cps_total = totalCPS;
        trainingPlanWeek.duration_total = totalDuration;
        trainingPlanWeek.distance_total = totalDistance;
        trainingPlanWeek.altimetry_total = totalAltimetry;
        trainingPlanWeek.calorie_total = totalCalorie;

        totalCPS = 0;
        totalDistance = 0;
        totalDuration = 0;
        totalAltimetry = 0;
        totalCalorie = 0;

        trainingPlanWeeks.push(trainingPlanWeek);
        trainingPlanWeek = {};
        weekOrder += 1;
      }
    })

    setSummary(trainingPlanWeeks);
  }

  const handleSubmit = async () => {
    let trainingPlanWeek = { trainingPlanDays: [] };
    const trainingPlanToSave = { ...trainingPlan, clone_type: 'SCHEMA', trainingPlanWeeks: [] };

    if (!trainingPlanToSave.name) {
      toast.error('Defina um nome para o plano de treino.')
      await setDataLoaded(true);
      return
    }

    let weekOrder = 1;
    let dayOrder = 1;

    let totalDistance = 0;
    let totalDuration = 0;
    let totalAltimetry = 0;
    let totalCalorie = 0;


    Object.keys(dndState.columns).forEach((col, cidx) => {
      let currColumn = dndState.columns[col];
      let trainingPlanGroupDay = { trainings: [] };

      if (col !== 'tcolumns') {
        trainingPlanGroupDay.date = currColumn.date;
        trainingPlanGroupDay.clone_type = "SCHEMA";
        trainingPlanGroupDay.day_order = dayOrder;

        if (currColumn.trainingIds.length > 0) {
          currColumn.trainingIds.forEach((treId, tidx) => {
            const { tId, tName, tIName, currTraining } = dndState.trainings[treId];

            totalDistance += currTraining.distance;
            totalDuration += currTraining.duration;
            totalAltimetry += currTraining.altimetry;
            totalCalorie += currTraining.calorie;

            trainingPlanGroupDay.trainings.push({ id: tId, name: tName, internal_name: tIName, due_date: currColumn.date, group_order: (tidx + 1), clone_type: 'SCHEMA', training: currTraining });
          })
        }

        if (trainingPlanGroupDay.trainings.length > 0) {
          trainingPlanWeek.trainingPlanDays.push(trainingPlanGroupDay);
        }

        trainingPlanGroupDay = {};
        dayOrder += 1;
      }

      if ((cidx + 1) % 7 === 0) {
        let firstDateOnWeek = getMonday(currColumn.date)
        let lastDateOnWeek = firstDateOnWeek.addDays(6);

        trainingPlanWeek.start_date = formatDate(firstDateOnWeek);
        trainingPlanWeek.end_date = formatDate(lastDateOnWeek);
        trainingPlanWeek.week_order = weekOrder;

        trainingPlanWeek.clone_type = "SCHEMA";
        trainingPlanWeek.name = "Nome do TrainingPlanWeek";
        trainingPlanWeek.description = "Description do TrainingPlanWeek";

        trainingPlanWeek.duration_total = totalDuration;
        trainingPlanWeek.distance_total = totalDistance;
        trainingPlanWeek.altimetry_total = totalAltimetry;
        trainingPlanWeek.calorie_total = totalCalorie;

        if (trainingPlanWeek.trainingPlanDays.length > 0) {
          trainingPlanToSave.trainingPlanWeeks.push(trainingPlanWeek);
        }
        trainingPlanWeek = { trainingPlanDays: [] };
        weekOrder += 1;
        dayOrder = 1;

        totalDistance = 0;
        totalDuration = 0;
        totalAltimetry = 0;
        totalCalorie = 0;
      }
    })

    trainingPlanToSave.author_id = profile.id;

    try {
      const resp = await api.post('training_plans', trainingPlanToSave)

      if (resp.status === 200) {
        toast.success('Plano de treino criado com sucesso')
        loadData();
        setLoadedItem({ title: 'N/A Carregado', itemName: 'Nenhum', itemID: 'N/A', itemInternalName: 'nenhum', itemType: '', data: {} })
        setSummary([])
      } else {
        toast.warn('Erro ao criar plano de treino')
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao salvar seu plano de treino')
    }
  }

  function formatDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  async function loadAthleteTrainingPlan(user, calendarInitDate = null, planId = null) {
    if (dataLoaded) {
      await setDataLoaded(false);
    }

    Promise.all([api.get(`/training_plans?userId=${user.id}&calendar=true`), api.get(`/user_activities?user_id=${user.id}`)])
      .then(async ([currTrainingPlanResp, userActsResp]) => {
        if (currTrainingPlanResp.data && currTrainingPlanResp.data.length > 0) {

          let allAthletePlans = currTrainingPlanResp.data

          if (planId) {
            allAthletePlans = allAthletePlans.filter(plan => plan.id === planId)
          }

          setAthleteCurrentTrainingPlan(allAthletePlans[0])
          setUserActivities(userActsResp.data)

          const { dndData, calendarInitAt } = await buildData(trainings, 24, allAthletePlans, userActsResp.data, 'PT', calendarInitDate);
          setCurrentCalendarDate(calendarInitAt)


          const columnOrdersList = Array.from(dndData.columnOrder);
          const lastIndex = (columnOrdersList.length - 1)
          columnOrdersList.splice(lastIndex, 1)

          setDndState(dndData);

          const currAgenda = [];
          let currentWeek = [];

          columnOrdersList.forEach((column, cidx) => {
            currentWeek.push(column)

            if ((cidx + 1) % 7 === 0) {
              currAgenda.push({ id: `row_${cidx + 1}`, days: currentWeek })
              currentWeek = [];
            } else if (columnOrdersList.length < 7 && (cidx + 1) === columnOrdersList.length) {
              currAgenda.push({ id: `row_${cidx + 1}`, days: currentWeek })
              currentWeek = [];
            }
          })

          setAgenda(currAgenda);
          setDataLoaded(true);
        }
      });
  }

  const handleLoadBackward = async () => {

    if (currentCalendarDate) {
      const newDate = moment(currentCalendarDate).subtract(1, 'months')

      setCurrentCalendarDate(newDate);

      if (planIdToLoadBackward) {
        loadAthleteTrainingPlan(loadedItem.data, newDate, planIdToLoadBackward)
      } else {
        loadAthleteTrainingPlan(loadedItem.data, newDate)
      }
    } else {
      setCurrentCalendarDate(new Date());
    }
  }

  async function loadTrainingPlan(trainingPlan) {
    await setDataLoaded(false);

    const currTrainingPlan = await api.get(`/training_plans/${trainingPlan.id}`)

    const { dndData } = await buildData(trainings, 24, currTrainingPlan.data, null, 'DEFAULT');

    const columnOrdersList = Array.from(dndData.columnOrder);
    const lastIndex = (columnOrdersList.length - 1)
    columnOrdersList.splice(lastIndex, 1)

    setDndState(dndData);
    calcSummary(dndData)

    const currAgenda = [];
    let currentWeek = [];
    columnOrdersList.forEach((column, cidx) => {
      currentWeek.push(column)

      if ((cidx + 1) % 7 === 0) {
        currAgenda.push({ id: `row_${cidx + 1}`, days: currentWeek })
        currentWeek = [];
      }
    })

    setAgenda(currAgenda);
    setDataLoaded(true);
  }

  function handleLoadedItem(data, type, planId = null) {
    switch (type) {
      case 'athlete':
        if (planId) {
          loadAthleteTrainingPlan(data, null, planId);
          setPlanIdToLoadBackward(planId)
        } else {
          loadAthleteTrainingPlan(data);
        }

        setLoadedItem({ title: 'Atleta Carregado', img: data.s_photo, itemName: data.name, itemID: data.id, itemInternalName: data.email, itemType: type, data: data })
        // LOAD TRAINING PLAN
        break;
      case 'trainingPlan':
        loadTrainingPlan(data);
        setLoadedItem({ title: 'Plano de Treino Carregado', img: '', itemName: data.name, itemID: data.id, itemInternalName: data.internal_name, itemType: type, data: data })
        break;
      default:
        break;
    }
  }

  function handleOpenSelectedDetails() {

    if (loadedItem && loadedItem.itemType === 'athlete') {
      setCurrentAthleteDetails(loadedItem.data);
      setAthleteDetailsModal(!athleteDetailsModal);
    } else if (loadedItem && loadedItem.itemType === 'trainingPlan') {
      setCurrentTrainingPlanDetails(loadedItem.data);
      setTrainingPlanDetailsModal(!trainingPlanDetailsModal);
    }
  }

  async function handleRemoveWeek(rowItem) {
    try {
      if (!rowItem || !rowItem?.days) return

      let trainingIdToRef = null

      for (const columnId of rowItem?.days) {
        if (dndState?.columns[columnId]) {
          const column = dndState?.columns[columnId];

          for (const trainingId of column?.trainingIds) {
            if (dndState?.trainings[trainingId]) {
              trainingIdToRef = dndState?.trainings[trainingId]?.tId
            }
          }
        }
      }

      if (!trainingIdToRef) return

      await api.delete(`training_plan_weeks/delete_by_training/${trainingIdToRef}`)

      loadAthleteTrainingPlan(loadedItem.data)
    } catch (error) {
      toast.error('Erro ao apagar semana')
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={!dataLoaded}>
        <ModalBody >
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner color="primary" /><span style={{ marginLeft: 20 }}>Aguarde...</span>
          </div>
        </ModalBody>
      </Modal>

      {trainingPlanModal && <TrainingPlanNewModal open={trainingPlanModal} changeTab={setActiveTab} toggle={setTrainingPlanModal} setTrainingPlan={setTrainingPlan} loadedItem={handleLoadedItem} />}

      {athleteDetailsModal && <AthleteDetailsModal open={athleteDetailsModal} changeTab={setActiveTab} toggle={setAthleteDetailsModal} athlete={currentAthleteDetails} loadedItem={handleLoadedItem} />}

      {trainingPlanDetailsModal && <TrainingPlanDetailModal open={trainingPlanDetailsModal} changeTab={setActiveTab} toggle={setTrainingPlanDetailsModal} trainingPlan={currentTrainingPlanDetails} loadedItem={handleLoadedItem} />}

      {trainingNewModal && <TrainingNewModal open={trainingNewModal} isTrainingEdit={isTrainingEdit} training={(isTrainingEdit ? trainingToEdit : trainingToSave)} toggle={setTrainingNewModal} />}

      {trainingActivityShowModal && <TrainingShowModal open={trainingActivityShowModal} training={trainingActivityToShow} removeItemAction={removeCalendarItem} trainingToRemove={trainingToRemove} trainingColumn={trainingActivityColumnToShow} toggle={() => setTrainingActivityShowModal(false)} openTrainingEdit={openTrainingEdit} />}

      {dataLoaded && <>
        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col lg={7}> <h4 className="page-title">Calendário</h4> </Col>
                <Col lg={5} className="mt-lg-3 mt-md-0"></Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody style={{ height: '100%', minHeight: 700 }}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Row >
                    <Col lg={3} style={{ background: 'white', }}>
                      <Card className={loadedItem.itemID > 0 ? 'shadow' : ''} onClick={() => handleOpenSelectedDetails()} style={{ border: '1px solid #4c70b7', borderRadius: 2, padding: 5, marginBottom: 10, cursor: 'pointer' }}>
                        <span style={{ marginBottom: 5 }}>{loadedItem.title}:</span>
                        <Row style={{ marginTop: 5 }}>
                          <Col lg={2}> <Avatar alt={loadedItem.itemName} src={loadedItem.img} /> </Col>
                          <Col lg={10} style={{ paddingTop: 3 }}>
                            <span style={{ fontSize: 14, color: 'black' }}>{loadedItem.itemName}</span> <br />
                            <span style={{ fontSize: 10 }}>#{loadedItem.itemID}</span> | <span style={{ fontSize: 10 }}>{loadedItem.itemInternalName}</span>
                          </Col>
                        </Row>
                      </Card>

                      <Nav tabs>
                        <NavItem className="leftSidebarNavItem"> <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} > Atletas </NavLink> </NavItem>
                        <NavItem className="leftSidebarNavItem"> <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} > Planos de Treino </NavLink> </NavItem>
                        <NavItem className="leftSidebarNavItem"> <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} > Treinos </NavLink> </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <InputGroup>
                                <Input placeholder="Pesquisar Atleta" value={searchQueryAthlete || ''} onChange={(e) => setSearchQueryAthlete(e.target.value)} />
                                <InputGroupAddon addonType="append"> <Button color="secondary" onClick={() => searchAthleteAction()}>Buscar</Button> </InputGroupAddon>
                              </InputGroup>

                              <div style={{ overflowY: 'scroll', paddingLeft: 10, paddingRight: 12, marginTop: 10, marginLeft: -5, width: '100%', float: 'left', height: '400px', position: 'relative' }}>
                                {athletes && athletes.map((athlete, aidx) => {
                                  return (
                                    <span key={`atKey_${aidx}`}>
                                      <Card body style={{ padding: 7, margin: 5 }} className="shadow" onClick={async () => {
                                        await setCurrentAthleteDetails(athlete);
                                        await setAthleteDetailsModal(!athleteDetailsModal);
                                      }}>
                                        <span>
                                          <Row>
                                            <Col lg={2}> <Avatar alt={athlete.name} src={athlete.s_photo} /> </Col>
                                            <Col lg={10} style={{ paddingTop: 3, paddingLeft: 3 }}>
                                              <span style={{ fontSize: 14, color: 'black' }}>{athlete.name}</span> <br />
                                              <span style={{ fontSize: 10 }}>#{athlete.id}</span> | <span style={{ fontSize: 10 }}>{athlete.email}</span>
                                            </Col>
                                          </Row>
                                        </span>
                                      </Card>
                                    </span>
                                  )
                                })}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <InputGroup style={{ marginTop: 0, paddingTop: 0 }}>
                                <Input placeholder="Pesquisar plano de treino" onChange={(e) => setSearchQueryTrainingPlan(e.target.value)} />
                                <InputGroupAddon addonType="append"> <Button color="secondary" onClick={() => searchTrainingPlanAction()}>Buscar</Button> </InputGroupAddon>
                              </InputGroup>

                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ marginTop: 10, marginRight: 10 }} size="sm" outline color="primary"> <MdAdd style={{ margin: 0, fontWeight: 'bold' }} /> <span style={{ fontWeight: 'bold' }}>Grupo</span> </Button>
                                <Button style={{ marginTop: 10 }} size="sm" outline color="primary" onClick={() => setTrainingPlanModal(true)}> <MdAdd style={{ margin: 0, fontWeight: 'bold' }} /> <span style={{ fontWeight: 'bold' }}>Plano de Treino</span> </Button>
                              </div>

                              <div style={{ overflowY: 'scroll', paddingLeft: 10, paddingRight: 12, marginTop: 10, marginLeft: -5, width: '100%', float: 'left', height: '400px', position: 'relative' }}>

                                {trainingPlans.map((tPlan, aidx) => {
                                  return (
                                    <span key={`tpKey_${aidx}`}>
                                      <Card body style={{ padding: 7, margin: 5 }} className="shadow" onClick={async () => {
                                        await setCurrentTrainingPlanDetails(tPlan);
                                        await setTrainingPlanDetailsModal(!trainingPlanDetailsModal);
                                      }}>
                                        <div>
                                          <Row>
                                            <Col lg={2}>
                                              <Avatar alt={tPlan.name} ariant="rounded" src="" style={{ color: '#fff' }}>
                                                <AssignmentIcon />
                                              </Avatar>
                                            </Col>
                                            <Col lg={10} style={{ paddingTop: 3 }}>
                                              <span style={{ fontSize: 14, color: 'black' }}>{tPlan.internal_name}</span> <br />
                                              <span style={{ fontSize: 10 }}>#{tPlan.id}</span> | <span style={{ fontSize: 10 }}>{tPlan.name}</span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    </span>
                                  )
                                })}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col sm="12">
                              <InputGroup>
                                <Input placeholder="Pesquisar Treinos" onChange={(e) => setSearchQueryTraining(e.target.value)} />
                                <InputGroupAddon addonType="append"> <Button color="secondary" onClick={() => {
                                  searchTrainingAction()
                                }}>Buscar</Button> </InputGroupAddon>
                              </InputGroup>

                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ marginTop: 10, marginRight: 10 }} size="sm" outline color="primary"> <MdAdd style={{ margin: 0, fontWeight: 'bold' }} /> <span style={{ fontWeight: 'bold' }}>Grupo</span> </Button>
                                <Button style={{ marginTop: 10 }} size="sm" outline color="primary" onClick={() => setTrainingNewModal(true)}> <MdAdd style={{ margin: 0, fontWeight: 'bold' }} /> <span style={{ fontWeight: 'bold' }}>Treino</span> </Button>
                              </div>

                              <div style={{ overflowY: 'scroll', paddingLeft: 10, paddingRight: 12, marginTop: 10, marginLeft: -5, width: '100%', float: 'left', height: '400px', position: 'relative' }}>
                                {buildTrainingList()}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                    <Col lg={9} style={{ background: '#e1e1e1', margin: 0, padding: 0 }}>
                      <Row style={{ paddingRight: 27, backgroundColor: 'white' }}>
                        <Col lg={9}>
                          <Row>
                            <Col style={{ minHeight: 30 }}> <div style={{ backgroundColor: '#gray', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#000', fontWeight: 'bold' }}> Segunda </p></div> </Col>
                            <Col style={{ minHeight: 30 }}> <div style={{ backgroundColor: '#gray', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#000', fontWeight: 'bold' }}> Terça </p></div> </Col>
                            <Col style={{ minHeight: 30 }}> <div style={{ backgroundColor: '#gray', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#000', fontWeight: 'bold' }}> Quarta </p></div> </Col>
                            <Col style={{ minHeight: 30 }}> <div style={{ backgroundColor: '#gray', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#000', fontWeight: 'bold' }}> Quinta </p></div> </Col>
                            <Col style={{ minHeight: 30 }}> <div style={{ backgroundColor: '#gray', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#000', fontWeight: 'bold' }}> Sexta </p></div> </Col>
                            <Col style={{ minHeight: 30 }}> <div style={{ backgroundColor: '#gray', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#000', fontWeight: 'bold' }}> Sábado </p></div> </Col>
                            <Col style={{ minHeight: 30 }}> <div style={{ backgroundColor: '#gray', width: '100%', height: 25, padding: 3 }}><p style={{ color: '#000', fontWeight: 'bold' }}> Domingo </p></div> </Col>
                          </Row>
                        </Col>
                        <Col lg={3}>
                          <Row> <Col lg={12} style={{ backgroundColor: 'white' }}><p style={{ color: '#000', fontWeight: 'bold' }}>Semana</p></Col> </Row>
                        </Col>
                      </Row>

                      {loadedItem && loadedItem.itemType === 'athlete' &&
                        <Row style={{ paddingRight: 27, backgroundColor: 'white' }}>
                          <Col lg={3}>
                            <p style={{ marginTop: 7, marginBottom: 7 }}>Visualizando a partir de: {currentCalendarDate ? moment(currentCalendarDate).format('DD/MM/YYYY') : '--/--/----'}</p>
                          </Col>
                          {/* md={{ size: 4, offset: 4 }} */}
                          <Col sm={9}>
                            {/* <Button style={{borderWidth: 1, borderColor: 'gray', margin: 5}} size="sm" color="link" onClick={handleLoadBackward}>Carregar +1 mês pra trás</Button> */}
                            <Button style={{ margin: 5 }} size="sm" outline color="primary" onClick={handleLoadBackward}>
                              {/* <MdAdd style={{margin:0, fontWeight: 'bold'}} />  */}
                              <span style={{ fontWeight: 'bold' }}>Visualizar +30 dias anteriores</span>
                            </Button>
                          </Col>
                        </Row>}
                      <div style={{ overflowY: 'scroll', paddingLeft: 10, paddingRight: 12, width: '100%', float: 'left', height: '570px', position: 'relative' }}>
                        {
                          agenda.map((rowItem, ridx) => {
                            return (
                              <Row key={`agKey_${ridx}`}>
                                <Col lg={9} sm={6}>
                                  <Row>
                                    {rowItem.days.map(columnId => {
                                      const column = dndState.columns[columnId];

                                      const trainings = column.trainingIds.map(trainingId => dndState.trainings[trainingId]);

                                      return <TrainingCalendarItem key={column.id} column={column} trainings={trainings} trainingDetailAction={openTrainingCalendar} removeItemAction={removeCalendarItem} showItemAction={handleOpenActivityDetail} />
                                    })}
                                  </Row>
                                </Col>
                                <Col lg={3} sm={6}>
                                  <Row>
                                    <Col lg={12} style={{ padding: 0 }}>
                                      <div style={{ backgroundColor: '#0065FF', width: '100%', height: 25, padding: 3 }}>
                                        <p style={{ color: '#f1f1f1' }}>
                                          Semana {ridx + 1}
                                          <span style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={() => handleRemoveWeek(rowItem)}>Apagar</span>
                                        </p>
                                      </div>
                                      <div style={{ backgroundColor: '#e1e1e1', padding: 5 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <span style={{ color: 'black', fontSize: 12 }}>Duração total</span>
                                          <div><span style={{ marginRight: 5, fontSize: 11 }}>{summary[ridx] ? formatTime(summary[ridx].duration_total * 60) : '--:--:--'}</span> <span style={{ fontSize: 11, color: 'black', fontWeight: 'bold', }}>--:--:--</span></div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                          <span style={{ color: 'black', fontSize: 12 }}>CPS</span>
                                          <div><span style={{ marginRight: 5, fontSize: 11 }}>{summary[ridx] ? Math.round(summary[ridx].cps_total) : 0}</span></div>

                                        </div>
                                        <Progress value="0" style={{ backgroundColor: '#a1a1a1' }}>0%</Progress>

                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                          <table>
                                            <thead>
                                              <tr>
                                                <td style={{ fontSize: 11, display: 'flex', justifyContent: 'flex-end' }}>Duração</td>
                                                <td><span style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 12, color: 'black' }}>{summary[ridx] ? formatTime(summary[ridx].duration_total * 60) : '--:--:--'}</span></td>
                                              </tr>
                                              <tr>
                                                <td style={{ fontSize: 11, display: 'flex', justifyContent: 'flex-end' }}>Distancia</td>
                                                <td><span style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 12, color: 'black' }}>{summary[ridx] ? `${summary[ridx].distance_total} km` : '-- km'}</span></td>
                                              </tr>
                                              <tr>
                                                <td style={{ fontSize: 11, display: 'flex', justifyContent: 'flex-end' }}>Elevação</td>
                                                <td><span style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 12, color: 'black' }}>{summary[ridx] ? `${summary[ridx].altimetry_total} m` : '-- m'}</span></td>
                                              </tr>
                                              <tr>
                                                <td style={{ fontSize: 11, display: 'flex', justifyContent: 'flex-end' }}>Caloria</td>
                                                <td><span style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 12, color: 'black' }}>{summary[ridx] ? `${summary[ridx].calorie_total} kcal` : '-- kcal'}</span></td>
                                              </tr>
                                            </thead>
                                          </table>
                                        </div>
                                      </div>

                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )
                          })
                        }
                      </div>
                    </Col>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginTop: 15 }}>
                      {loadedItem && loadedItem.itemType === 'trainingPlan' && loadedItem.itemID <= 0 &&
                        <Button style={{ marginRight: 10 }} color="success" onClick={handleSubmit}>SALVAR</Button>}
                    </div>
                  </Row>
                </DragDropContext>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>}
    </React.Fragment>
  );
}