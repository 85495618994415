import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import Pagination from "react-reactstrap-pagination";

import { toast } from 'react-toastify';
import moment from 'moment';

import api from '~/services/api';
import { formatTrainingCategory, formatTrainingIntensity } from '~/services/util'

import TrainingNewModal from '../Training/new/TrainingNewModal';
import TrainingShowModal from '../Training/show/TrainingShowModal';

import Loader from '~/components/Loader/Loader';

export default class Training extends Component {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);

        this.state = {
            pageData: [],
            loading: true,
            trainingNewModal: false,
            trainingShowModal: false,
            trainingToSave: {trainingGroups: []},
            isTrainingEdit: false,
            selectedPage: 1,
            totalItems: 0,
            perPage: 20,
            lastPage: 1,
            searchQuery: ''
        }

        this.loadData();
    }

    loadData = async (selectedPage) => {
        const { data } = await api.get(`trainings?page=${selectedPage}&limit=${this.state.perPage}`);

        this.setState({...this.state, pageData: data.data, loading: false, totalItems: data.total, lastPage: data.lastPage, selectedPage: selectedPage });
    }

    formatTrainingVisibility = (visible, type) => {
        const has = visible.split(',').filter((item) => item === `${type}`);

        return has && has.length !== 0
    }

    handleEditTraining = (training) => {
        this.setState({...this.state, trainingToSave: training, isTrainingEdit: true, trainingNewModal: true })
    }

    handleDeleteTraining = async (training) => {
        var result = window.confirm("Tem certeza que deseja remover permanentemente esse treino?");

            if (result) {
                const resp = await api.delete(`trainings/${training.id}`)

                if (resp && resp.status === 204) {
                    toast.success('Treino apagado com sucesso');
                    this.loadData();
                } else {
                    toast.error('Erro ao deletar treino');
                }
            }
    }

    handleSearchTraining = async (event) => {
        if (event && event.preventDefault) event.preventDefault()

        if (this.state.searchQuery.length === 0) {
            this.loadData(1)
            return
        }

        this.setState({ ...this.state, loading: true });

        const { data } = await api.get(`trainings?searchQuery=${this.state.searchQuery}&page=1&limit=${this.state.perPage}`);

        this.setState({...this.state, pageData: data.data, loading: false, totalItems: data.total });
    }

    handleSearchTrainingInPaginate = async (page) => {
        this.setState({ ...this.state, loading: true });

        const { data } = await api.get(`trainings?searchQuery=${this.state.searchQuery}&page=${page}&limit=${this.state.perPage}`);

        this.setState({...this.state, pageData: data.data, loading: false, totalItems: data.total });
    }

    render() {

        return (
            <React.Fragment>
                {this.state.loading && <Loader />}

                <Row>
                    <Col>
                        <div className="page-title-box">
                            <Row>
                                <Col lg={7}>
                                    <h4 className="page-title">Treinos</h4>
                                </Col>
                                <Col lg={5} className="mt-lg-3 mt-md-0">
                                    
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card style={{ overflow: 'auto' }}>
                            <CardBody>
                                <p>Lista de Treinos</p>
                                <form onSubmit={this.handleSearchTraining}>
                                    <InputGroup>
                                        <Input placeholder="pesquisar por nome interno" onChange={(e) => this.setState({ searchQuery: e.target.value })} />
                                        <InputGroupAddon addonType="append">
                                            <Button color="success">Pesquisar</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </form>
                                <Button style={{marginRight: 10, marginBottom: 10, marginTop: 15}} color="success" onClick={() => this.setState({...this.state, trainingNewModal: true, trainingToSave: {trainingGroups: []} })}>Cadastrar novo Treino</Button>
                                <>
                                <table className="table table-centered table-hover mb-0" id="btn-editable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Tipo</th>
                                            <th>Visibilidade</th>
                                            <th>Intensidade</th>
                                            <th>Data de criação</th>
                                            <th className="tabledit-toolbar-column"></th>
                                            <th className="tabledit-toolbar-column"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        { this.state.pageData.map( (data, dIdx) => (
                                            <tr id="1" key={`id_${dIdx}`}>
                                                <td><span className="tabledit-span tabledit-identifier">{data.id}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">
                                                    <button style={{}} className="btn btn-link" color="primary" onClick={() => this.setState({...this.state, trainingToShow: data, trainingShowModal: true })}>{data.internal_name}</button> <br />
                                                    <span style={{fontSize: 12, color: '#a1a1a1', marginLeft: 15}}>({data.name})</span>
                                                </span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{formatTrainingCategory(data.type)}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">
                                                    {this.formatTrainingVisibility(data.visible_type, '1') && <span className="badge badge-primary">ADM</span>}&nbsp;
                                                    {this.formatTrainingVisibility(data.visible_type, '2') && <span className="badge badge-success">Explorar</span>}
                                                </span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{formatTrainingIntensity(data.intensity)}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{data.created_at ? moment(data.created_at).format('DD/MM/YYYY') : '-'}</span></td>
                                                <td className="tabledit-view-mode"><Button color="primary" onClick={() => this.handleEditTraining(data)}>Editar</Button></td>
                                                <td className="tabledit-view-mode"><Button color="danger" onClick={() => this.handleDeleteTraining(data)}>Apagar</Button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} style={{ overflow: 'auto' }}>
                        <Pagination
                            firstPageText="Início"
                            lastPageText="Fim"
                            previousPageText="Anterior"
                            nextPageText="Próximo"
                            totalItems={this.state.totalItems}
                            pageSize={this.state.perPage}
                            maxPaginationNumbers={5}
                            defaultActivePage={1}
                            onSelect={this.state.searchQuery.length === 0 ? this.loadData : this.handleSearchTrainingInPaginate}
                        />
                    </Col>
                </Row>
                {this.state.trainingNewModal && <TrainingNewModal refreshTrainings={this.loadData} open={this.state.trainingNewModal} training={this.state.trainingToSave} isTrainingEdit={this.state.isTrainingEdit} toggle={() => this.setState({...this.state, trainingNewModal: false, isTrainingEdit: false })} /> }
                {this.state.trainingShowModal && <TrainingShowModal open={this.state.trainingShowModal} training={this.state.trainingToShow} toggle={() => this.setState({...this.state, trainingShowModal: false })} /> }
        </React.Fragment>

        );
        
    };
}
