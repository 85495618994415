import React, { useState, useEffect } from 'react';
import {
  Row, Col, Modal, ModalBody, ModalFooter, Button,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup,
} from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import api from '~/services/api';

export default function NewUser(props) {
  const [show, setShow] = useState(true);
  const [subscription, setSubscription] = useState(null)
  const [folders, setFolders] = useState([]);
  const [showDDFolder, setShowDDFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({ name: 'Selecionar Pasta', id: -1 });
  const [roles, setRoles] = useState([]);
  const [showDDRoles, setShowDDRoles] = useState(false);
  const [currentRole, setCurrentRole] = useState({ name: 'Selecionar Perfil', id: -1 });
  const [coach, setCoach] = useState();
  useEffect(() => {
    if (props.user && props.user.id > 0) {
      delete props.user.password

      props.user.birth = moment(props.user.birth).format('YYYY-MM-DD')

      if (props.user.return_to_trainings_at) {
        props.user.return_to_trainings_at = moment(props.user.return_to_trainings_at).utcOffset(0, false).format('YYYY-MM-DD')
      }
      setCurrentRole(props.user.roles[0])
    }

    if (props.user && props.user.folder && props.user.folder.id > 0)
      setCurrentFolder(props.user.folder)

    if (props.user && props.user.subscriptions && props.user.subscriptions.length > 0) {
      setSubscription(props.user.subscriptions[0])
    }

  }, [props.user])

  useEffect(() => {
    loadData();
  }, [])

  async function loadData() {
    Promise.all([api.get('folders'), api.get('roles')])
      .then(([folderResp, rolesResp]) => {
        setFolders(folderResp.data);
        setRoles(rolesResp.data);
      });

    if (props.user.coach_id) {
      const coat = await api.get(`/users/${props.user.coach_id}`);
      setCoach(coat.data)
    }
  }

  const handleUserSubmit = async (event, errors, values) => {
    if (!values.name) { toast.error('Atribua um nome para o usuário'); return }

    if (!values.email) { toast.error('Atribua um email para o usuário'); return; }

    if (currentRole.id > 0) {
      values.role_id = currentRole.id
    } else {
      toast.error('Atribua uma permissão para o usuário'); return;
    }

    if (currentFolder.id > 0) { values.folder_id = currentRole.id }

    if (subscription && props.user && props.user.id > 0) {
      values.subscription_id = subscription.id
    }

    try {
      let resp = {};

      let toastMessage = ''
      let toastErrorMessage = ''

      if (props.user && props.user.id > 0) {
        resp = await api.put(`users/${props.user.id}`, values)
        toastMessage = 'Usuário atualizado com sucesso'
        toastErrorMessage = 'Erro ao atualizar usuário'
      } else {
        resp = await api.post('users', values)
        toastMessage = 'Usuário criado com sucesso'
        toastErrorMessage = 'Erro ao criar usuário'
      }

      if (resp.status === 200) {
        toast.success(toastMessage)
        setShow(!show)

        if (props.user && props.user.id > 0) {
          if (props.searchQuery && props.searchQuery.length > 0) {
            props.handleSetSearchQuery(props.user.email)
            props.handleSearchUser()
          } else {
            props.loadUsers(props.selectedPage)
          }
        }
      } else {
        toast.warn(toastErrorMessage)
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao salvar usuário')
    }
  }

  return (
    <Modal style={{ maxWidth: '2600px', width: '95%', maxHeight: '1600px', height: '95%' }} isOpen={show} toggle={() => props.toggle(!show)}>

      <AvForm onSubmit={handleUserSubmit} model={props.user}>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <AvField style={{}} label="Nome" placeholder="Nome completo" name="name" />
                  <AvField style={{}} label="Email" placeholder="Email" name="email" />
                  <AvField style={{}} label="Telefone" placeholder="phone" name="phone" />
                  <AvField style={{}} label="Senha" placeholder="Senha" name="password" />
                  <AvField style={{}} label="Confirmação de senha" placeholder="Confirmação de senha" name="password_confirmation" />
                  <AvField style={{}} label="Biografia" placeholder="Texto descritivo" name="bio" />
                  {coach?.name && <>
                    <h2><span>Treinador</span></h2>
                    <h3><span className={` badge badge-primary`}> {coach?.name}</span></h3>
                  </>}
                </Col>
                <Col lg={6}>
                  <AvField style={{}} type="date" label="Nascimento" placeholder="Data de nascimento" name="birth" />
                  <AvField type="select" label="Gênero" name="genre" style={{}}>
                    <option>M</option>
                    <option>F</option>
                  </AvField>
                  <AvField style={{}} label="Peso" placeholder="Peso em kg" name="weight" />
                  <AvField style={{}} label="Altura" placeholder="Altura em cm" name="height" />
                  <AvField type="select" label="Tipo de acesso" name="subscription_type" style={{}}>
                    <option value="FREE">FREE</option>
                    <option value="ESSENTIAL">ESSENCIAL</option>
                    <option value="PERSONAL">PERSONAL</option>
                    <option value="BASIC">BASIC</option>
                    <option value="START">START</option>
                  </AvField>
                  <AvField style={{}} type="date" label="Data para retorno" placeholder="Data para retorno" name="return_to_trainings_at" />

                  <Row>
                    <Col lg={4}>
                      <Dropdown isOpen={showDDFolder} toggle={() => setShowDDFolder(!showDDFolder)} >
                        <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentFolder.name} </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true, order: 890, fn: (data) => {
                                return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                              }
                            }
                          }} >
                          {folders.map((elem, index) => {
                            return (
                              <DropdownItem onClick={() => setCurrentFolder(elem)}>{elem.name}</DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col lg={4}>
                      <Dropdown isOpen={showDDRoles} toggle={() => setShowDDRoles(!showDDRoles)} >
                        <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentRole?.name} </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true, order: 890, fn: (data) => {
                                return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                              }
                            }
                          }} >
                          {roles.map((elem, index) => {
                            return (
                              <DropdownItem onClick={() => setCurrentRole(elem)}>{elem.name}</DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => props.toggle(!show)}>Cancelar</Button>
          <FormGroup>
            <Button color="success" >Salvar e Fechar</Button>
          </FormGroup>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
