import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Card, CardBody, Input, Alert } from 'reactstrap';
import Pagination from "react-reactstrap-pagination";
import { toast } from 'react-toastify';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';
import ModalEdit from './ModalEdit';
import ModalCreate from './ModalCreate';
import './styles.css';
import { formatBigText } from '~/services/util';
import moment from 'moment';
import { debounce } from 'lodash'

export default function PushesScheduled() {
    const [pushes, setPushes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [lastPage, setLastPage] = useState(1);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    const [pushToShow, setPushToShow] = useState(null);
    const [copyingData, setCopyingData] = useState(false);

    const loadPushes = useCallback(async (selectedPage, perPage = 50, searchText = '') => {
        try {
            setLoading(true);

            const URL = `push_notification_schedules?page=${selectedPage}&limit=${perPage}&searchText=${searchText}`;

            const response = await api.get(URL);

            setPushes(response.data?.data);
            setTotalItems(response.data?.total);
            setPerPage(response.data?.perPage);
            setLastPage(response.data?.lastPage);
            setSelectedPage(response.data?.page);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error('Erro ao carregar a agenda!');
        }
    }, [])

    useEffect(() => {
        loadPushes(1, perPage);
    }, [loadPushes, perPage]);

    const formatUserSubscriptionType = (type) => {
        switch (type) {
            case 'BASIC':
                return 'Básico';
            case 'ESSENTIAL':
                return 'Essencial';
            case 'PERSONAL':
                return 'Personal';
            case 'FREE':
                return 'Gratuito';
            case 'START':
                return 'Start';
            default:
                return '';
        }
    }

    const formatStatus = (status) => {
        switch (status) {
            case 'PENDING':
                return 'Pendente';
            case 'SENDING':
                return 'Enviando';
            case 'SENT':
                return 'Enviada';
            default:
                return '-';
        }
    }

    const formatStatusStyleClass = (status) => {
        switch (status) {
            case 'PENDING':
                return 'badge-danger';
            case 'SENDING':
                return 'badge-warning text-dark';
            case 'SENT':
                return 'badge-primary';
            default:
                return '';
        }
    }

    const delayedQuery = useRef(
        debounce(e => {
            loadPushes(1, perPage, e)
        }, 1000)
    ).current

    const copyToNewPushSchedule = () => {
        setModalEdit(false)
        setCopyingData(true)
        setModalCreate(true)
    }

    return (
        <>
            {loading && <Loader />}

            {
                pushToShow &&
                <ModalEdit
                    push={pushToShow}
                    showModal={modalEdit}
                    toggle={() => setModalEdit(prev => !prev)}
                    loadPushes={loadPushes}
                    copyToNewPushSchedule={copyToNewPushSchedule}
                    formatUserSubscriptionType={formatUserSubscriptionType}
                />
            }

            <ModalCreate
                showModal={modalCreate}
                toggle={() => setModalCreate(prev => !prev)}
                loadPushes={loadPushes}
                dataToCopy={pushToShow}
                copyingData={copyingData}
                setCopyingData={setCopyingData}
                formatUserSubscriptionType={formatUserSubscriptionType}
            />

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Pushes agendadas</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card style={{ overflow: 'auto' }}>
                        <CardBody>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="card-body-header-title">
                                    <p>Total ({totalItems})</p>
                                    <p className="ml-2 btn btn-primary" onClick={() => setModalCreate(true)}>Agendar nova push</p>
                                </div>
                                <div className="card-body-header-title">
                                    <Input type="text" name="search_text" id="search_text" onChange={e => delayedQuery(e.target.value)} placeholder='Buscar nos textos' />
                                </div>
                            </div>

                            <table className="table table-centered table-hover mb-0" id="btn-editable">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Título</th>
                                        <th>Conteúdo</th>
                                        <th>Link</th>
                                        <th>Tipo(s) de assinatura</th>
                                        <th>Estado(s)</th>
                                        <th>Agendada para</th>
                                        <th>Status</th>
                                        <th>Por</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        !!pushes.length &&
                                        pushes.map((push, pIdx) => (
                                            <tr key={`pus_${pIdx}`} className="table-tbody-tr" onClick={() => {
                                                setPushToShow(push);
                                                setModalEdit(true);
                                            }}>
                                                <td>
                                                    <span className="tabledit-span tabledit-identifier">{push.id || 'n/a'}</span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {push.title || 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {push.content ? formatBigText(push.content) : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {push.action_url ? formatBigText(push.action_url) : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {
                                                            push.user_subscription_type ?
                                                                <>
                                                                    {
                                                                        push.user_subscription_type.split(',').map(value => (formatUserSubscriptionType(value))).join(',')
                                                                    }
                                                                </>
                                                                :
                                                                'Todos'
                                                        }
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {push.user_state || 'Todos'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {push.due_at ? moment(push.due_at).format('DD/MM/YYYY HH:mm') : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className={`tabledit-span badge ${push.status ? formatStatusStyleClass(push.status) : ''}`}>
                                                        {push.status ? formatStatus(push.status) : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {push.user.name || 'n/a'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ overflow: 'auto' }}>
                    <Pagination
                        firstPageText="Início"
                        lastPageText="Fim"
                        previousPageText="Anterior"
                        nextPageText="Próximo"
                        totalItems={totalItems}
                        pageSize={perPage}
                        maxPaginationNumbers={lastPage}
                        defaultActivePage={selectedPage}
                        onSelect={loadPushes}
                    />
                </Col>
            </Row>
        </>

    );
}
