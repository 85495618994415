import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Pagination from "react-reactstrap-pagination";
import { toast } from 'react-toastify';
import { api2 } from '~/services/api';
import Loader from '~/components/Loader/Loader';
import ModalEdit from './ModalEdit'
import ModalCreate from './ModalCreate'
import './styles.css';
import { format, lastDayOfMonth } from 'date-fns'
import { debounce } from 'lodash'
import RangeSlider from '~/components/RangeSlider';
import CustomThumb from '~/components/RangeSlider/CustomThumb';

const months = [
    { name: 'Todos', id: 'all' },
    { name: 'Janeiro', id: 0 },
    { name: 'Fevereiro', id: 1 },
    { name: 'Março', id: 2 },
    { name: 'Abril', id: 3 },
    { name: 'Maio', id: 4 },
    { name: 'Junho', id: 5 },
    { name: 'Julho', id: 6 },
    { name: 'Agosto', id: 7 },
    { name: 'Setembro', id: 8 },
    { name: 'Outubro', id: 9 },
    { name: 'Novembro', id: 10 },
    { name: 'Dezembro', id: 11 },
]

const states = [
    { name: 'Todos', id: 'all' },
    { name: 'Acre', id: 'ac' },
    { name: 'Alagoas', id: 'al' },
    { name: 'Amapá', id: 'ap' },
    { name: 'Amazonas', id: 'am' },
    { name: 'Bahia', id: 'ba' },
    { name: 'Ceará', id: 'ce' },
    { name: 'Distrito Federal', id: 'df' },
    { name: 'Espírito Santo', id: 'es' },
    { name: 'Goiás', id: 'go' },
    { name: 'Maranhão', id: 'ma' },
    { name: 'Mato Grosso do Sul', id: 'ms' },
    { name: 'Mato Grosso', id: 'mt' },
    { name: 'Minas Gerais', id: 'mg' },
    { name: 'Pará', id: 'pa' },
    { name: 'Paraíba', id: 'pb' },
    { name: 'Paraná', id: 'pr' },
    { name: 'Pernambuco', id: 'pe' },
    { name: 'Piauí', id: 'pi' },
    { name: 'Rio de Janeiro', id: 'rj' },
    { name: 'Rio Grande do Norte', id: 'rn' },
    { name: 'Rio Grande do Sul', id: 'rs' },
    { name: 'Rondônia', id: 'ro' },
    { name: 'Roraima', id: 'rr' },
    { name: 'Santa Catarina', id: 'sc' },
    { name: 'São Paulo', id: 'sp' },
    { name: 'Sergipe', id: 'se' },
    { name: 'Tocantins', id: 'to' },
]

const perPage = 20

export default function RaceCalendar() {
    const [races, setRaces] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedPage, setSelectedPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [showDDMonth, setShowDDMonth] = useState(false)
    const [showDDState, setShowDDState] = useState(false)
    const [selectedMonth, setSelectedMonth] = useState({ name: 'Todos', id: 'all' })
    const [selectedState, setSelectedState] = useState({ name: 'Todos', id: 'all' })
    const [modalEdit, setModalEdit] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    const [raceToShow, setRaceToShow] = useState(null)
    const [name, setName] = useState('')
    const [city, setCity] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [displayDistances, setDisplayDistances] = useState([1, 42])
    const [distancesFilter, setDistancesFilter] = useState([0, 0])
    const [withCoupon, setWithCoupon] = useState(false)
    const [withStructure, setWithStructure] = useState(false)

    const loadRaces = useCallback(async (page) => {
        setLoading(true)

        const skip = page > 1 ? (page - 1) * perPage : 0

        let URL = `race_calendar?skip=${skip}&limit=${perPage}`

        if (city) {
            URL += `&city=${city}`
        }

        if (selectedState?.id !== 'all') {
            URL += `&state=${selectedState.id}`
        }

        const month = selectedMonth?.id

        if (month === 'all' && startDate) {
            URL += `&start_date=${startDate}`
        }

        if (month === 'all' && endDate) {
            URL += `&end_date=${endDate}`
        }

        if (month !== 'all') {
            const baseDate = new Date()

            if (startDate && endDate) {
                const year = new Date(endDate).getFullYear()
                baseDate.setFullYear(year, month, 1)
            } else {
                baseDate.setMonth(month, 1)
            }

            baseDate.setHours(0, 0, 0)

            URL += `&start_date=${format(baseDate, 'yyyy-MM-dd')}`

            baseDate.setHours(23, 59, 59)

            URL += `&end_date=${format(lastDayOfMonth(baseDate), 'yyyy-MM-dd')}`
        }

        if (distancesFilter[1] > distancesFilter[0]) {
            URL += `&min_distance=${distancesFilter[0]}&max_distance=${distancesFilter[1] === 42 ? 999 : distancesFilter[1]}`
        }

        if (withCoupon) {
            URL += `&has_coupon=${withCoupon}`
        }

        if (withStructure) {
            URL += `&has_structure=${withStructure}`
        }

        if (name) {
            URL += `&name=${name}`
        }

        try {
            const { data } = await api2.get(URL)

            setRaces(data?.data)
            setTotalItems(data?.total)
            setSelectedPage(page)
        } catch (error) {
            toast.error('Erro ao carregar calendário de provas!')
        } finally {
            setLoading(false)
        }
    }, [city, distancesFilter, endDate, name, selectedMonth, selectedState, startDate, withCoupon, withStructure])

    useEffect(() => {
        loadRaces(1);
    }, [loadRaces])

    const formatDistances = (distances) => {
        const formatted = distances.filter(distance => distance)

        if (formatted.length) return formatted.join('-')

        return 'n/a'
    }

    const formatCoupons = (coupons) => {
        const formatted = coupons.filter(coupon => coupon && coupon.code)

        if (formatted.length) return formatted.map(coupon => coupon.code).join(',')

        return 'n/a'
    }

    const handleYear = (year) => {
        if (year.length === 0) {
            setStartDate('')
            setEndDate('')
            return
        }

        const baseDate = new Date()

        baseDate.setFullYear(year, 0, 1)
        baseDate.setHours(0, 0, 0)

        setStartDate(format(baseDate, 'yyyy-MM-dd'))

        baseDate.setFullYear(year, 11, 31)
        baseDate.setHours(23, 59, 59)

        setEndDate(format(baseDate, 'yyyy-MM-dd'))
    }

    const cityDelayedQuery = useRef(
        debounce(e => {
            setCity(e)
        }, 1000)
    ).current

    const yearDelayedQuery = useRef(
        debounce(e => {
            if (e.length === 0 || e.length > 3) handleYear(e)
        }, 1000)
    ).current

    const nameDelayedQuery = useRef(
        debounce(e => {
            setName(e)
        }, 1000)
    ).current

    const distancesDelayedQuery = useRef(
        debounce(e => {
            setDistancesFilter(e)
        }, 1000)
    ).current

    const handleFilterDistances = (distances) => {
        setDisplayDistances(distances);

        distancesDelayedQuery(distances)
    }

    return (
        <>
            {raceToShow && <ModalEdit race={raceToShow} showModal={modalEdit} toggle={() => setModalEdit(prev => !prev)} loadRaces={loadRaces} />}

            <ModalCreate showModal={modalCreate} toggle={() => setModalCreate(prev => !prev)} loadRaces={loadRaces} />

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Corridas</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card style={{ overflow: 'auto' }}>
                        <CardBody>
                            {loading && <Loader />}

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="card-body-header-title">
                                    <p>Total ({totalItems})</p>
                                    <p className="ml-2 btn btn-primary" onClick={() => setModalCreate(true)}>Cadastrar</p>
                                </div>

                                <div className="card-body-header-status mb-3">
                                    <div className="mr-2" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>Nome</span>
                                        <input style={{ padding: 2 }} type="text" name="searchName" id="searchName" onChange={e => nameDelayedQuery(e.target.value)} placeholder="Todos" />
                                    </div>

                                    <div className="mr-2" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>Cidade</span>
                                        <input style={{ padding: 2 }} type="text" name="searchCity" id="searchCity" onChange={e => cityDelayedQuery(e.target.value)} placeholder="Todas" />
                                    </div>

                                    <div className="mr-2" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>Ano</span>
                                        <input style={{ padding: 2 }} type="text" name="searchYear" id="searchYear" onChange={e => yearDelayedQuery(e.target.value)} placeholder="Todos" />
                                    </div>

                                    <div className="card-body-header-status-dropdown mr-2">
                                        <span>Mês</span>
                                        <Dropdown size="sm" isOpen={showDDMonth} toggle={() => setShowDDMonth(prev => !prev)} >
                                            <DropdownToggle className="card-body-header-status-dropdown-toggle">{selectedMonth?.name}</DropdownToggle>

                                            <DropdownMenu
                                                modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true, order: 890, fn: (data) => {
                                                            return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                                        }
                                                    }
                                                }} >
                                                {months.map((elem, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={`mo_${index}`}
                                                            onClick={() => {
                                                                setSelectedPage(1)
                                                                setSelectedMonth(elem)
                                                            }}>
                                                            {elem.name}
                                                        </DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>

                                    <div className="card-body-header-status-dropdown">
                                        <span>Estado</span>
                                        <Dropdown size="sm" isOpen={showDDState} toggle={() => setShowDDState(prev => !prev)} >
                                            <DropdownToggle className="card-body-header-status-dropdown-toggle">{selectedState?.name}</DropdownToggle>

                                            <DropdownMenu
                                                modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true, order: 890, fn: (data) => {
                                                            return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                                        }
                                                    }
                                                }} >
                                                {states.map((elem, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={`st_${index}`}
                                                            onClick={() => {
                                                                setSelectedPage(1)
                                                                setSelectedState(elem)
                                                            }}>
                                                            {elem.name}
                                                        </DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <div className="card-body-header-status mb-5">
                                    <div className="mr-2" style={{ display: 'flex', flexDirection: 'column', width: '200px' }}>
                                        <span>Distância em km</span>
                                        <RangeSlider
                                            min={1}
                                            max={42}
                                            values={displayDistances}
                                            callback={(e) => handleFilterDistances(e)}
                                            customThumb={CustomThumb}
                                        />
                                    </div>

                                    <div className="mr-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <span>Somente com cupom</span>
                                        <input style={{ marginLeft: 5 }} type="checkbox" name="withCoupon" id="withCoupon" onChange={e => setWithCoupon(e.target.checked)} />
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <span>Somente com estrutura</span>
                                        <input style={{ marginLeft: 5 }} type="checkbox" name="withStructure" id="withStructure" onChange={e => setWithStructure(e.target.checked)} />
                                    </div>
                                </div>
                            </div>

                            <table className="table table-centered table-hover mb-0" id="btn-editable">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Data</th>
                                        <th>Distâncias</th>
                                        <th>Estado</th>
                                        <th>Cidade</th>
                                        <th>Cupons</th>
                                        <th>Teremos estrutura?</th>
                                        <th>Cadastrada em</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {races &&
                                        races.length > 0 &&
                                        races.map((race, rIdx) => (
                                            <tr key={`rac_${rIdx}`} className="table-tbody-tr" onClick={() => {
                                                setRaceToShow(race)
                                                setModalEdit(true)
                                            }}>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.name || 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.date ? format(new Date(race.date), 'dd/MM/yyyy') : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.distance && race.distance.length ? formatDistances(race.distance) : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.state || 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.city || 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.coupons && race.coupons.length ? formatCoupons(race.coupons) : 'n/a'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.structure ? 'Sim' : 'Não'}
                                                    </span>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <span className="tabledit-span">
                                                        {race.created_at ? format(new Date(race.created_at), 'dd/MM/yyyy') : 'n/a'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs={12} style={{ overflow: 'auto' }}>
                    <Pagination
                        firstPageText="Início"
                        lastPageText="Fim"
                        previousPageText="Anterior"
                        nextPageText="Próximo"
                        totalItems={totalItems}
                        pageSize={perPage}
                        maxPaginationNumbers={5}
                        defaultActivePage={selectedPage}
                        onSelect={loadRaces}
                    />
                </Col>
            </Row>
        </>
    );
}
